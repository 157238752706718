body {
    font-family: Helvetica, sans-serif;
    background-color: #fdfdfe;
    margin: 0;
    padding: 0;
}

.chat {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 867px;
    background: #fdfdfe;
    overflow-y: scroll;
}

.chat-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: rgb(44, 185, 253);
    background: -moz-linear-gradient(90deg, rgba(44, 185, 253, 1) 14%, rgba(94, 206, 253, 1) 33%, rgba(134, 225, 254, 1) 62%);
    background: -webkit-linear-gradient(90deg, rgba(44, 185, 253, 1) 14%, rgba(94, 206, 253, 1) 33%, rgba(134, 225, 254, 1) 62%);
    background: linear-gradient(90deg, rgba(44, 185, 253, 1) 14%, rgba(94, 206, 253, 1) 33%, rgba(134, 225, 254, 1) 62%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2cb9fd", endColorstr="#86e1fe", GradientType=1);
    color: #fdfdfe;
    font-weight: 600;
    font-size: 22px;
}

.chat-heading p {
    padding: 0;
    margin: 0;
}

.chat-heading img {
    width: 95px;
}

.chat-heading-sect {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 95px;
}

.chat-pro-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    background: #d6f3fd;
    color: #2CB9FDFF;
    font-weight: 600;
    font-size: 14px;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.chat-messages::-webkit-scrollbar {
    width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
    background: #ddd;
}

.chat-messages::-webkit-scrollbar-thumb {
    background: #bdbdbd;
}

.message {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.message:last-of-type {
    margin: 0;
}

.message-item {
    max-width: 450px;
    padding: 15px;
    border-radius: 15px;
    background: #eeeeee;
}

.message-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.message-info-name {
    margin-right: 10px;
    font-weight: bold;
}

.message-info-time {
    font-size: 0.85em;
}

.left-msg .message-item {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .message-item {
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, rgba(44, 185, 253, 1) 14%, rgba(94, 206, 253, 1) 33%, rgba(134, 225, 254, 1) 62%) border-box;
    border: 1px solid transparent;
    border-bottom-right-radius: 0;
}

.chat-inputarea {
    display: flex;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #eeeeee;
}

.chat-input {
    flex: 1;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    font-size: 18px;
    background: #eeeeee;
    width: 300px;
    min-height: 50px;
    resize: vertical;
    overflow-y: hidden;
    transition: height 0.2s;
}

.chat-input:focus-visible {
    border: 1px solid rgba(94, 206, 253, 1);
    outline: rgba(94, 206, 253, 1);
}

input[type="file"]::file-selector-button {
    border-radius: 20px;
    border: none;
    padding: 10px;
    margin-right: 15px;
    background: rgba(44, 185, 253, 1);
    color: #fdfdfe;
    transition: 1s;
}

.chat-send-btn {
    margin-left: 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border: none;
    background: none;
}

button {
    cursor: pointer;
}

.chat-timer {
    color: #cecece;
    margin-left: 10px;
}

.typed {
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    animation: typing;
    animation-duration: 3s;
    animation-timing-function: steps(30, end);
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    color: #424040;
}

@keyframes typing {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

@media (max-width: 800px) {
    .chat {
        height: 100vh;
    }
}

@media (min-width: 800px) {
    .chat {
        height: calc(100vh - 50px);
        margin: 20px auto;
        border-radius: 20px;
        overflow: hidden;
        -webkit-box-shadow: 0 0 49px -11px rgba(44, 185, 253, 0.67);
        -moz-box-shadow: 0 0 49px -11px rgba(44, 185, 253, 0.67);
        box-shadow: 0 0 49px -11px rgba(44, 185, 253, 0.67);
    }
}

#scroller * {
    overflow-anchor: none;
}

#anchor {
    overflow-anchor: auto;
    height: 1px;
}
